import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/api/auth";

export const AuthLayout = ({ children }) => {
  const { status, error } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [logged, setLogged] = useState(null);

  useEffect(() => {
    if (status === "success") {
      setLogged(true);
    } else if (status === "error") {
      setLogged(false);
    }
  }, [status]);

  useEffect(() => {
    const isAuthRoute = location.pathname.startsWith("/authentication");

    if (logged === false && !isAuthRoute) {
      navigate("/authentication/sign-in");
    }

    if (logged === true && isAuthRoute) {
      navigate("/dashboard");
    }
  }, [logged, location.pathname, navigate]);

  console.log("Auth error:", error);
  console.log("Current status:", status);
  console.log("User is logged in:", logged);

  return <>{children}</>;
};

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
