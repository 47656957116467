/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import { useCreateAdmin } from "hooks/api/admin";

export function CreateAdmin({ open, setOpen }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });

  const createAdmin = useCreateAdmin();
  const onSubmit = (data) => {
    createAdmin.mutate(data);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText align="center">
          <MDTypography variant="h4" fontWeight="medium">
            Create Admin
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must have at least 3 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <MDInput
                  {...field}
                  type="text"
                  label="Name"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Invalid email address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <MDInput
                  {...field}
                  type="email"
                  label="Email"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <MDInput
                  {...field}
                  type="password"
                  label="Password"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </MDBox>
          <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
        </MDBox>
      </MDBox>

      <DialogActions>
        <MDButton onClick={() => setOpen(false)} variant="contained" color="warning">
          Cancel
        </MDButton>
        <MDButton type="submit" variant="contained" color="info" onClick={handleSubmit(onSubmit)}>
          {createAdmin.isPending ? <CircularProgress size={24} color="inherit" /> : "Create"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

CreateAdmin.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
