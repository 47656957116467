/* eslint-disable react/prop-types */
import React from "react";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { Icon, Menu, MenuItem } from "@mui/material";
import { useDisableUser } from "hooks/api/user";
import { ProfileView } from "./view";

export function UserMenu({
  children,
  id,
  enable,
  profile_image,
  user_id,
  first_name,
  surname,
  email,
  phone_number,
  date_of_birth,
  gender,
  referer_code,
  has_verify,
  is_disabled,
  interests,
  bvn,
  createdAt,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openView, setOpenView] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOpen = () => {
    setOpenView(true);
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const user = useDisableUser();
  return (
    <div>
      {children}
      <Icon onClick={handleClick}>more_vert</Icon>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ProfileView
          handleViewClose={handleViewClose}
          openView={openView}
          handleViewOpen={handleViewOpen}
          id={id}
          profile_image={profile_image}
          first_name={first_name}
          surname={surname}
          email={email}
          phone_number={phone_number}
          date_of_birth={date_of_birth}
          gender={gender}
          referer_code={referer_code}
          has_verify={has_verify}
          is_disabled={is_disabled}
          interests={interests}
          bvn={bvn}
          createdAt={createdAt}
        >
          View
        </ProfileView>
        {enable ? (
          <MenuItem
            onClick={() => {
              user.mutate({ user_id, enable: 0 });
              handleClose();
            }}
          >
            Enable
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              user.mutate({ user_id, enable: 1 });
              handleClose();
            }}
          >
            Disable
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

UserMenu.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  enable: PropTypes.number,
};
