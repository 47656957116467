import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { createAdmin, enableAdmin, getAllAdmin, upgradeAdmin } from "services/api/admin";

export const useGetAllAdmin = () => {
  return useQuery({
    queryKey: ["all_admin"],
    queryFn: getAllAdmin,
  });
};

export const useEnableAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["enable_admin"],
    mutationFn: ({ id, code }) => enableAdmin({ id, code }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["all_admin"],
      });
      toast("update has been made");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};

export const useUpgradeAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["enable_admin"],
    mutationFn: ({ id, role }) => upgradeAdmin({ id, role }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["all_admin"],
      });
      toast("update has been made");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};

export const useCreateAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["create_admin"],
    mutationFn: ({ email, password, name }) =>
      createAdmin({
        email,
        password,
        name,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["all_admin"],
      });
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};
