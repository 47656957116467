import { z } from "zod";

const payloadSchema = z.object({
  BASE_URL: z.string(),
  UPSTREAM_URL: z.string(),
});

export const envPayload = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  UPSTREAM_URL: process.env.REACT_APP_UPSTREAM_URL,
};

export const env = payloadSchema.parse(envPayload);
console.log(env);
