import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { disableUser, getAllUsers, getCount, searchUser } from "services/api/user";
import toast from "react-hot-toast";
import { useAuth } from "../auth";

export const useGetCount = () => {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["count", data?.admin_key],
    queryFn: () => getCount(data?.admin_key),
    enabled: !!data?.admin_key,
  });
};

export const useGetAllUsers = () => {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["all_users", data?.admin_key],
    queryFn: () => getAllUsers(data?.admin_key),
    enabled: !!data?.admin_key,
  });
};

export const useDisableUser = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["admin"]);
  return useMutation({
    mutationKey: ["disable_users"],
    mutationFn: ({ user_id, enable }) =>
      disableUser({ key: user?.admin_key, data: { user_id, enable } }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["all_users"],
      });
      toast("update has been made");
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });
};

export const useSearchUser = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["admin"]);
  return useMutation({
    mutationKey: ["search_user"],
    mutationFn: ({ search }) => searchUser({ key: user?.admin_key, data: { search } }),
  });
};
