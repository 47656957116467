/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import React from "react";
import toast from "react-hot-toast";
import { useSendOTP, useVerifyOTP } from "hooks/api/auth";

function Cover() {
  const location = useLocation();
  const [otp, setOtp] = React.useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const aOtp = useVerifyOTP();
  const sOtp = useSendOTP();
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter The OTP sent to your email {location.state?.email}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MuiOtpInput value={otp} onChange={handleChange} />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => {
                if (otp.length === 4) {
                  aOtp.mutate({ otp: otp, email: location.state?.email });
                }
              }}
            >
              {aOtp.isPending ? <CircularProgress size={24} /> : "Verify OTP"}
            </MDButton>
          </MDBox>
          <MDTypography
            onClick={() => {
              sOtp.mutate({ email: location.state?.email });
            }}
            display="block"
            variant="button"
            color="info"
            my={1}
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            RESEND OTP
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
