/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useGetAllUsers } from "hooks/api/user";
import { Icon } from "@mui/material";
import { UserMenu } from "../actions";

export default function data({ allUser, search }) {
  const User = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={"https://github.com/shadcn.png"} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          sx={{ textTransform: "capitalize" }}
          style={{ textTransform: "capitalize" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const users = search?.users ? search?.users : allUser.data?.users || [];

  return {
    columns: [
      { Header: "user", accessor: "user", width: "45%", align: "left" },
      { Header: "phone", accessor: "phone", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "date of birth", accessor: "dob", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows:
      users.length > 0
        ? users.map((user) => ({
            user: <User name={`${user?.surname} ${user?.first_name}`} email={user?.email} />,
            phone: user?.phone_number ? user?.phone_number.replace("+234", "0") : "N/A",
            status: (
              <MDBox ml={-1}>
                <MDBadge
                  badgeContent={user.is_disabled ? "Disabled" : "Active"}
                  color={user?.is_disabled ? "error" : "success"}
                  variant="gradient"
                  size="sm"
                />
              </MDBox>
            ),
            dob: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {user?.date_of_birth ? user.date_of_birth : "N/A"}
              </MDTypography>
            ),
            action: (
              <UserMenu id={user?.user_id} enable={user?.is_disabled} {...user}>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  .
                </MDTypography>
              </UserMenu>
            ),
          }))
        : [],
  };
}
