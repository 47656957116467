import React from "react";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { Icon, Menu, MenuItem } from "@mui/material";
import { useEnableAdmin, useUpgradeAdmin } from "hooks/api/admin";

export function AdminMenu({ children, id, enabled, role }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enable = useEnableAdmin();
  const upgrade = useUpgradeAdmin();
  return (
    <div>
      {children}
      <Icon onClick={handleClick}>more_vert</Icon>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {enabled ? (
          <MenuItem
            onClick={() => {
              enable.mutate({ id, code: 0 });
              handleClose();
            }}
          >
            Disable
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              enable.mutate({ id, code: 1 });
              handleClose();
            }}
          >
            Enable
          </MenuItem>
        )}
        {role === "ADMIN" ? (
          <MenuItem
            onClick={() => {
              upgrade.mutate({ id, role: "SUPER_ADMIN" });
              handleClose();
            }}
          >
            Upgrade
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              upgrade.mutate({ id, role: "ADMIN" });
              handleClose();
            }}
          >
            Downgrade
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

AdminMenu.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  enabled: PropTypes.number,
  role: PropTypes.string,
};
