/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { ReactJsonCsv, useJsonToCsv } from "react-json-csv";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import userTableData from "layouts/all-users/data/userTableData";
import { useGetAllUsers, useSearchUser } from "hooks/api/user";
import { LoaderIcon } from "react-hot-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Icon, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

function Users() {
  const allUser = useGetAllUsers();
  const { saveAsCsv } = useJsonToCsv();
  const [search, setSearch] = React.useState();
  const doSearch = useSearchUser();

  const { columns, rows } = userTableData({ allUser, search: doSearch?.data });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Stack direction="row" justifyContent="end">
        <MDButton
          title="Download CSV"
          iconOnly={true}
          circular={true}
          onClick={() =>
            saveAsCsv({
              data: allUser.data.users,
              fields: {
                profile_image: "Profile Image",
                first_name: "First Name",
                surname: "Surname",
                email: "Email",
                phone_number: "Phone Number",
                date_of_birth: "Date of Birth",
                gender: "Gender",
                referer_code: "Referer Code",
                has_verify: "Has Verified",
                is_disabled: "Status",
                bvn: "BVN",
                createdAt: "Created At",
              },
              filename: `${crypto.randomUUID()}`,
            })
          }
          variant={"contained"}
          color={"dark"}
          className="rounded-full"
          size={"large"}
        >
          <Icon>cloud_download</Icon>
        </MDButton>
      </Stack>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Users Table
                </MDTypography>
                <MDBox
                  sx={{
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  <form
                    style={{
                      display: "flex",
                      gap: "4px",
                    }}
                  >
                    <MDInput
                      onChange={(event) => setSearch(event.target.value)}
                      size="small"
                      color="primary"
                    />
                    <MDButton onClick={() => doSearch.mutate({ search })}>Search</MDButton>
                  </form>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {allUser.isLoading ? (
                  <div style={{ margin: "10px" }}>
                    <LoaderIcon style={{ width: "25px", height: "25px" }} />
                  </div>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={true}
                    showTotalEntries={false}
                    noEndBorder
                    isSorted={true}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
