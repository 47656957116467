import to from "await-to-js";
import { fetcher, mutation } from ".";
import { AxiosError } from "axios";
import { z } from "zod";
import { whoAmISchema } from "schema";

export const signup = async ({ email, password, agree, name }) => {
  const payload = { email, password, agree, name };
  const [err, response] = await to(mutation.post("/api/auth/signup", payload));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const login = async ({ email, password, remember }) => {
  const payload = { email, password, remember };
  const [err, response] = await to(mutation.post("/api/auth/signin", payload));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const sendOtp = async ({ email }) => {
  const payload = { email };
  const [err, response] = await to(mutation.post("/api/otp/send", payload));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const verifyOTP = async ({ otp }) => {
  const payload = { otp };
  const [err, response] = await to(mutation.post("/api/otp/verify", payload));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const whoAmI = async () => {
  const [err, response] = await to(fetcher.get("/api/admin/whoami"));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  const { data } = response;
  const validData = whoAmISchema.safeParse(data);
  if (validData.success) {
    return validData.data;
  }
  throw error.response?.data;
};

export const logOut = async () => {
  const [err, response] = await to(mutation.post("/api/auth/logout", {}));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const forgotPassword = async ({ email }) => {
  const [err, response] = await to(mutation.post("/api/auth/forgot-password", { email }));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const resetPassword = async ({ password, token }) => {
  const [err, response] = await to(
    mutation.post(
      "/api/auth/reset-password",
      { password },
      {
        headers: {
          "x-reset-token": token,
        },
      }
    )
  );
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};
