/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useLogin, useResetPassword } from "hooks/api/auth";
import toast from "react-hot-toast";

function Basic() {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirm_password: "",
      password: "",
    },
  });

  const resetPassword = useResetPassword();
  const onSubmit = (data) => {
    resetPassword.mutate(data, {
      onError: () => {
        toast.error("Token has expired!");
      },
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Password"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  required: "Confirm Password is required",
                  minLength: {
                    value: 6,
                    message: "Confirm Password must have at least 6 characters",
                  },
                  validate: (value) => value === watch("password") || "The passwords do not match",
                }}
                render={({ field, fieldState: { error } }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                loading={resetPassword.isPending}
              >
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  {resetPassword.isPending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Reset"
                  )}
                </MDTypography>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
