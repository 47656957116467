/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useGetAllUsers } from "hooks/api/user";
import { Icon } from "@mui/material";
import { AdminMenu } from "../action";
import { format, parseISO } from "date-fns";

const parseToDate = (data) => {
  const parsedDate = parseISO(data);
  return format(parsedDate, "dd MMMM yyyy, HH:mm:ss");
};
export default function data({ adminUsers }) {
  const Admin = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={"https://github.com/shadcn.png"} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const admins = adminUsers.data || [];
  console.log(admins);

  return {
    columns: [
      { Header: "Full Name", accessor: "name", width: "45%", align: "left" },
      { Header: "role", accessor: "role", align: "left" },
      { Header: "Verified", accessor: "verified", align: "center" },
      { Header: "status", accessor: "enabled", align: "center" },
      { Header: "Created", accessor: "createdAt", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows:
      admins.length > 0
        ? admins.map((admin) => ({
            name: <Admin name={`${admin.admin.name}`} email={admin.admin.email} />,
            role: admin.admin.role,
            verified: (
              <MDBox ml={-1}>
                <MDBadge
                  badgeContent={admin.admin.verified ? "verified" : "pending"}
                  color={admin.admin.verified ? "success" : "warning"}
                  variant="gradient"
                  size="sm"
                />
              </MDBox>
            ),
            enabled: (
              <MDBox ml={-1}>
                <MDBadge
                  badgeContent={admin.admin.enabled ? "Active" : "Disabled"}
                  color={admin.admin.enabled ? "success" : "error"}
                  variant="gradient"
                  size="sm"
                />
              </MDBox>
            ),
            createdAt: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {admin.admin.createdAt ? parseToDate(admin.admin.createdAt) : "N/A"}
              </MDTypography>
            ),
            action: (
              <AdminMenu id={admin.admin.id} enabled={admin.admin.enabled} role={admin.admin.role}>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                ></MDTypography>
              </AdminMenu>
            ),
          }))
        : [],
  };
}
