import React, { Component } from "react";
import styled, { css } from "styled-components";

/* eslint-disable react/prop-types */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { format, getDay, getYear, getMonth } from "date-fns";
import profilesListData from "layouts/profile/data/profilesListData";

export function ProfileView({
  profile_image,
  openView,
  handleViewClose,
  handleViewOpen,
  children,
  id,
  first_name,
  surname,
  email,
  phone_number,
  date_of_birth,
  gender,
  referer_code,
  has_verify,
  is_disabled,
  interests,
  bvn,
  createdAt,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <MenuItem variant="outlined" onClick={handleViewOpen}>
        {children}
      </MenuItem>
      <Dialog
        fullScreen={fullScreen}
        open={openView}
        onClose={handleViewClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"lg"}
      >
        <Rect2Row>
          <Rect2>
            <Rect1>
              <img
                style={{
                  "border-radius": "10px",
                  width: "196px",
                  height: "195px",
                }}
                src={profile_image ? profile_image : "https://github.com/shadcn.png"}
                alt="profile image"
              />
            </Rect1>
            <ReferalCode>Referal Code</ReferalCode>
            <ReferaalCode1>{referer_code}</ReferaalCode1>
            <Bvn>BVN</Bvn>
            <ReferaalCode3>{bvn}</ReferaalCode3>
            <Verified>VERIFIED</Verified>
            {has_verify ? (
              <Rect10>
                <Verified2>Verified</Verified2>{" "}
              </Rect10>
            ) : (
              <Rect13>
                <NVerified2>Pending</NVerified2>
              </Rect13>
            )}
            <Enabled>ENABLED</Enabled>
            {!is_disabled ? (
              <Rect10>
                <Active>Active</Active>
              </Rect10>
            ) : (
              <Rect12>
                <NActive>Disabled</NActive>
              </Rect12>
            )}{" "}
            <CreatedAt>Created at</CreatedAt>
            <Active1>{createdAt && format(createdAt, "yyyy : MM : dd h:mm a")}</Active1>
          </Rect2>
          <UserDetailsColumn>
            <UserDetails>User Details</UserDetails>
            <FullName>Full Name</FullName>
            <Rect3>
              <FullName1>
                {surname} {first_name}
              </FullName1>
            </Rect3>
            <EmailAddress>Email Address</EmailAddress>
            <Rect4>
              <SleykingzGmailCom>{email}</SleykingzGmailCom>
            </Rect4>
            <ContactPhoneNo>Contact Phone No</ContactPhoneNo>
            <Rect5>
              <SleykingzGmailCom1>{phone_number}</SleykingzGmailCom1>
            </Rect5>
            <DateOfBirth>Date of Birth</DateOfBirth>
            <Rect6Row>
              <Rect6>
                <SleykingzGmailCom2>{date_of_birth && getDay(date_of_birth)}</SleykingzGmailCom2>
              </Rect6>
              <Rect7>
                <June>{date_of_birth && getMonth(date_of_birth)}</June>
              </Rect7>
              <Rect8>
                <June1>{date_of_birth && getYear(date_of_birth)}</June1>
              </Rect8>
            </Rect6Row>
            <Gender>Gender</Gender>
            <Rect9>
              <Male>{gender}</Male>
            </Rect9>
          </UserDetailsColumn>
        </Rect2Row>
      </Dialog>
    </React.Fragment>
  );
}

const Rect = styled.div`
  display: flex;
  width: 653px;
  height: 606px;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 7px;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  margin-top: 158px;
  margin-left: 317px;
  border-style: solid;
`;

const Rect2 = styled.div`
  width: 256px;
  height: 602px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 7px;
  flex-direction: column;
  display: flex;
`;

const Rect1 = styled.div`
  width: 196px;
  height: 195px;
  background-color: rgba(155, 155, 155, 1);
  border-radius: 38px;
  opacity: 0.69;
  margin-top: 27px;
  margin-left: 32px;
`;

const ReferalCode = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 120px;
  font-size: 15px;
  margin-top: 31px;
  margin-left: 43px;
`;

const ReferaalCode1 = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 81px;
  font-size: 15px;
  margin-top: 1px;
  margin-left: 43px;
`;

const Bvn = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 120px;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 43px;
`;

const ReferaalCode3 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 142px;
  font-size: 15px;
  margin-left: 43px;
`;

const Verified = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 120px;
  font-size: 15px;
  margin-top: 14px;
  margin-left: 43px;
`;

const Rect10 = styled.div`
  width: 76px;
  height: 34px;
  background-color: rgba(126, 211, 33, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 41px;
  border-style: solid;
`;

const Verified2 = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 54px;
  font-size: 15px;
  margin-top: 7px;
  margin-left: 11px;
`;
const NVerified2 = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  height: 19px;
  width: 54px;
  color: #121212;
  font-size: 15px;
  margin-top: 7px;
  margin-left: 11px;
`;

const Enabled = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 120px;
  font-size: 15px;
  margin-top: 19px;
  margin-left: 43px;
`;

const Rect11 = styled.div`
  width: 76px;
  height: 34px;
  background-color: rgba(126, 211, 33, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 41px;
  border-style: solid;
`;

const Rect12 = styled.div`
  width: 76px;
  height: 34px;
  background-color: red;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  color: white;
  display: flex;
  margin-top: 9px;
  margin-left: 41px;
  border-style: solid;
`;
const Rect13 = styled.div`
  width: 76px;
  height: 34px;
  background-color: yellow;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  color: white;
  display: flex;
  margin-top: 9px;
  margin-left: 41px;
  border-style: solid;
`;

const Active = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 45px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 19px;
`;
const NActive = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  color: white;
  height: 19px;
  width: 45px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 19px;
`;

const CreatedAt = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 120px;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 43px;
`;

const Active1 = styled.span`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 19px;
  width: 160px;
  font-size: 15px;
  margin-top: 3px;
  margin-left: 43px;
`;

const UserDetails = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 31px;
  width: 164px;
  font-size: 30px;
  margin-left: 4px;
`;

const FullName = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 71px;
  font-size: 15px;
  margin-top: 30px;
  margin-left: 4px;
`;

const Rect3 = styled.div`
  width: 304px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 4px;
  border-style: solid;
`;

const FullName1 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 189px;
  font-size: 15px;
  margin-top: 9px;
  margin-left: 11px;
`;

const EmailAddress = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 102px;
  font-size: 15px;
  margin-top: 29px;
  margin-left: 2px;
`;

const Rect4 = styled.div`
  width: 304px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 4px;
  border-style: solid;
`;

const SleykingzGmailCom = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 153px;
  font-size: 15px;
  margin-top: 9px;
  margin-left: 11px;
`;

const ContactPhoneNo = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 126px;
  font-size: 15px;
  margin-top: 26px;
`;

const Rect5 = styled.div`
  width: 304px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 4px;
  border-style: solid;
`;

const SleykingzGmailCom1 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 127px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 10px;
`;

const DateOfBirth = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 92px;
  font-size: 15px;
  margin-top: 27px;
`;

const Rect6 = styled.div`
  width: 42px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const SleykingzGmailCom2 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 24px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 10px;
`;

const Rect7 = styled.div`
  width: 133px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-left: 20px;
  border-style: solid;
`;

const June = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 39px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 47px;
`;

const Rect8 = styled.div`
  width: 85px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-left: 23px;
  border-style: solid;
`;

const June1 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 37px;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 24px;
`;

const Rect6Row = styled.div`
  height: 36px;
  flex-direction: row;
  display: flex;
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 1px;
`;

const Gender = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 19px;
  width: 52px;
  font-size: 15px;
  margin-top: 29px;
`;

const Rect9 = styled.div`
  width: 304px;
  height: 36px;
  background-color: rgba(155, 155, 155, 0.18);
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.18);
  flex-direction: column;
  display: flex;
  margin-top: 9px;
  margin-left: 4px;
  border-style: solid;
`;

const Male = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  height: 19px;
  width: 43px;
  font-size: 15px;
  margin-top: 6px;
  margin-left: 10px;
`;

const UserDetailsColumn = styled.div`
  width: 308px;
  flex-direction: column;
  display: flex;
  margin-left: 44px;
  margin-top: 22px;
  margin-bottom: 92px;
`;

const Rect2Row = styled.div`
  height: 602px;
  flex-direction: row;
  display: flex;
  margin-top: 1px;
  margin-left: 2px;
  margin-right: 43px;
`;
