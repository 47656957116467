import { z } from "zod";

export const loginFormSchema = z.object({
  email: z
    .string()
    .email({
      message: "Invalid email format",
    })
    .max(50),
  password: z
    .string({
      message: "Password is required",
      required_error: "Password is required",
    })
    .min(6, {
      message: "Password must be at least 6 characters",
    }),
  remember: z.boolean().default(false),
});

export const regFormSchema = z.object({
  email: z
    .string()
    .email({
      message: "Invalid email format",
    })
    .max(50),
  password: z
    .string({
      message: "Password is required",
      required_error: "Password is required",
    })
    .min(6, {
      message: "Password must be at least 6 characters",
    }),
  agree: z.boolean(),
  name: z.string().min(3, {
    message: "Name must be at least 3 characters",
  }),
});

export const otpSchema = z.object({
  otp: z
    .string({
      message: "OTP is required",
      required_error: "OTP is required",
    })
    .length(4, { message: "OTP must contain 4 numbers" }),
});

export const searchParamsSchema = z.object({
  email: z
    .string()
    .email({
      message: "Invalid email format",
    })
    .max(50),
  enabled: z.coerce.number().optional(),
});

export const whoAmISchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
  password: z.string(),
  role: z.enum(["ADMIN", "SUPER_ADMIN"]),
  verified: z.number().int().min(0).max(1),
  enabled: z.number().int().min(0).max(1),
  admin_key: z.string().optional(),
  permissions: z.array(z.string()),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const createAdminSchema = z.object({
  name: z.string().min(3, { message: "Name must be at least 3 characters" }),
  email: z.string().email({ message: "Invalid email format" }),
  password: z.string().min(6, { message: "Password must be at least 6 characters" }),
});
