import to from "await-to-js";
import { fetcher, mutation } from ".";
import { AxiosError } from "axios";

export const getAllAdmin = async () => {
  const [err, response] = await to(fetcher.get("/api/admin"));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const enableAdmin = async ({ id, code }) => {
  const [err, response] = await to(mutation.put("/api/admin/enable", { id, code }));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const upgradeAdmin = async ({ id, role }) => {
  const [err, response] = await to(mutation.put("/api/admin/upgrade", { id, role }));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};

export const createAdmin = async ({ email, password, name }) => {
  const [err, response] = await to(mutation.post("/api/admin/create", { email, password, name }));
  if (err instanceof AxiosError) {
    throw err.response?.data;
  }
  return response.data;
};
