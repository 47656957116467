import to from "await-to-js";
import { AxiosError } from "axios";
import { fetcherUpstream, mutationUpstream } from ".";

export const countAllUsers = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/count-all-users", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const countVerifiedUsers = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/count-user-verified", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const countPendingUsers = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/count-user-pending", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const countEnabledUsers = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/count-user-enable", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const countDisabledUser = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/count-user-disabled", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const getCount = async (key) => {
  let err, all, verified, pending;
  [err, all] = await to(countAllUsers(key));
  [err, verified] = await to(countVerifiedUsers(key));
  [err, pending] = await to(countPendingUsers(key));
  // [err, enabled] = await to(countEnabledUsers(key!));
  // [err, disabled] = await to(countDisabledUser(key!));
  console.error(err);
  return {
    allCount: all.count ?? 0,
    verifiedCount: verified.count ?? 0,
    pendingCount: pending.count ?? 0,
    // enabledCount: enabled.count ?? 0,
    // disabledCount: disabled.count ?? 0,
  };
};

export const getAllUsers = async (key) => {
  const [err, response] = await to(
    fetcherUpstream.get("/v1/admin/get-all-users", {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const disableUser = async ({ key, data }) => {
  const [err, response] = await to(
    mutationUpstream.put("/v1/admin/disable-enable-user", data, {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};

export const searchUser = async ({ key, data }) => {
  const [err, response] = await to(
    mutationUpstream.post("/v1/admin/search-user", data, {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
  );
  if (err instanceof AxiosError) {
    throw new Error(err.response?.data.message);
  }
  return response.data;
};
