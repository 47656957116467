/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Toaster } from "react-hot-toast";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./index.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { QueryProvider } from "context/query-provider";
import { CookiesProvider } from "react-cookie";
import AuthLayout from "layouts/authentication/guard";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <QueryProvider>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <AuthLayout>
            <App />
          </AuthLayout>
        </CookiesProvider>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            className: "",
            style: {
              padding: "16px",
              color: "#fff",
              fontSize: "1rem",
              backgroundColor: "#333",
            },
          }}
        />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </QueryProvider>
);
