// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import adminTableData from "layouts/admin-users/data/adminTableData";
import { useGetAllUsers } from "hooks/api/user";
import { LoaderIcon } from "react-hot-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Stack } from "@mui/material";
import { useGetAllAdmin } from "hooks/api/admin";
import React from "react";
import { CreateAdmin } from "./form/create-admin";

function Users() {
  const [open, setOpen] = React.useState(false);
  const adminUsers = useGetAllAdmin();
  const { columns, rows } = adminTableData({ adminUsers });
  return (
    <>
      <MDBox pt={6} pb={3}>
        <CreateAdmin open={open} setOpen={setOpen} />
      </MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack direction="row" justifyContent="end">
          <MDButton color="info" onClick={() => setOpen(true)}>
            CREATE ADMIN
          </MDButton>
        </Stack>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Admin Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {adminUsers.isLoading ? (
                    <div style={{ margin: "10px" }}>
                      <LoaderIcon style={{ width: "25px", height: "25px" }} />
                    </div>
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={false}
                      noEndBorder
                      canSearch={true}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Users;
