import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotPassword,
  login,
  logOut,
  resetPassword,
  sendOtp,
  signup,
  verifyOTP,
  whoAmI,
} from "services/api/auth";

export const useSendOTP = () => {
  return useMutation({
    mutationKey: ["send_otp"],
    mutationFn: async ({ email }) => sendOtp({ email }),
    onSuccess: () => {
      toast.success("OTP sent successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};

export const useVerifyOTP = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["verify_otp"],
    mutationFn: async ({ otp }) => verifyOTP({ otp }),

    onSuccess: (data) => {
      const delay = 2000;

      if (data.enabled === 1 && data.verified === 1) {
        setTimeout(() => {
          navigate("/dashboard");
        }, delay);
      } else {
        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, delay);
      }
    },

    onError: (err) => {
      toast.error(err.message);
    },
  });
};
export const useAuth = () => {
  return useQuery({
    queryKey: ["admin"],
    queryFn: whoAmI,
  });
};

export const useRegister = () => {
  const navigate = useNavigate();
  const otp = useSendOTP();
  return useMutation({
    mutationKey: ["register"],
    mutationFn: ({ email, password, agree, name }) => signup({ email, password, agree, name }),
    onSuccess: (data) => {
      otp.mutate(
        { email: data.email },
        {
          onSuccess: (data) => {
            navigate("/authentication/otp", {
              state: { email: data.email },
            });
          },
        }
      );
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};
export const useLogin = () => {
  const navigate = useNavigate();
  const otp = useSendOTP();
  return useMutation({
    mutationKey: ["login"],
    mutationFn: ({ email, password, remember }) => login({ email, password, remember }),
    onSuccess: (data) => {
      const email = data.email;
      otp.mutate(
        { email },
        {
          onSuccess: (data) => {
            navigate("/authentication/otp", {
              state: { email: email },
            });
          },
        }
      );
    },
    onError: (err) => {
      if (err.verified === 0) {
        otp.mutate(
          { email: err.email },
          {
            onSuccess: (data) => {
              navigate("/authentication/otp", {
                state: { email: data.email },
              });
            },
          }
        );
      } else {
        toast.error(err.message);
      }
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["logout"],
    mutationFn: () => logOut(),
    onSuccess: () => {
      navigate("/auth/login");
    },
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationKey: ["forgot_password"],
    mutationFn: ({ email }) => forgotPassword({ email }),
    onSuccess: () => {
      console.log("success");
    },
  });
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.pathname.trim().split("/")[3];
  return useMutation({
    mutationKey: ["reset_password"],
    mutationFn: ({ password }) => resetPassword({ password, token }),
    onSuccess: (data) => {
      console.log(data);
      toast.success("Password reset successfully");
      navigate("/authentication/sign-in");
    },
  });
};
