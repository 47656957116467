import to from "await-to-js";
import axios from "axios";
import { AxiosError } from "axios";
import { env } from "config";

export const fetcher = axios.create({
  baseURL: env.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const mutation = axios.create({
  baseURL: env.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

mutation.interceptors.request.use((config) => {
  return config;
});

export const fetcherUpstream = axios.create({
  baseURL: env.UPSTREAM_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

fetcherUpstream.interceptors.request.use(
  async (request) => {
    console.log("req was hit");
    return request;
  },
  async (error) => {
    console.log("error from req", error);
    return Promise.reject(error);
  }
);

fetcherUpstream.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 404) {
      const header = error.response.config.headers["Authorization"];
      const [err, response] = await to(
        axios.put(
          `${env.BASE_URL}/api/admin/renew-key`,
          {},
          {
            headers: {
              "x-renew-key": header,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
      );
      if (err instanceof AxiosError) {
        throw new Error(err.response?.data.message);
      }
      location.reload();
    }
    return Promise.reject(error);
  }
);

export const mutationUpstream = axios.create({
  baseURL: env.UPSTREAM_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
